import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/logo.png'


const _withScopeId = n => (_pushScopeId("data-v-7d8e1a7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]
const _hoisted_2 = { class: "user-info" }
const _hoisted_3 = { class: "user-info-nickname" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "header" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 4 }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _imports_0,
                alt: _ctx.config.pc_title,
                class: "logo"
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 12,
            class: "menu"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Menu, {
                mode: "horizontal",
                selectedKeys: _ctx.current,
                "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.current) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuItem, {
                    key: "home",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.url('Home')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("首页")
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (v) => {
                    return (_openBlock(), _createBlock(_component_MenuItem, {
                      key: v.id,
                      onClick: ($event: any) => (_ctx.url('articleList',v))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(v.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["selectedKeys"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 8,
            class: "user"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentTime), 1)
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "btn-login",
                        type: "primary",
                        shape: "round",
                        onClick: _ctx.login
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("注册/登录")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, !_ctx.userInfo?.nickname]
                  ]),
                  _withDirectives(_createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_Dropdown, null, {
                          overlay: _withCtx(() => [
                            _createVNode(_component_Menu, { class: "user-info" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_MenuItem, {
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.url('myspace')))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("我的领地")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_MenuItem, { onClick: _ctx.logOut }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("退出登录")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_Avatar, {
                                size: 24,
                                src: _ctx.userInfo?.avatar
                              }, null, 8, ["src"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.userInfo?.nickname), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.userInfo?.nickname]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}